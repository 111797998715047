import React, {useEffect, useState}from 'react'
import dataDummy from '../assets/dataDummy.json'
import axios from "axios"

function App() {

    const [dataModel, setdataModel] = useState([]);

    const getDataAll = async () => {
        console.log(dataDummy);
        return (<>null</>)
    }


    
    useEffect(() => {
        getDataAll();
    }, []);

    return ( <></> );
}

export default App;