import React from 'react';

class JadwalKerja extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            karyawan: [
                { nama: "Karyawan A", jam_kerja: 8 },
                { nama: "Karyawan B", jam_kerja: 8 },
                { nama: "Karyawan C", jam_kerja: 8 }
            ],
            waktu_kerja_maksimum: 24,
            jadwal: []
        };
    }

    componentDidMount() {
        this.generateSchedule();
    }

    generateSchedule = () => {
        const { karyawan, waktu_kerja_maksimum } = this.state;
        let jadwal = [];
        let sisaWaktu = waktu_kerja_maksimum;
        let shift = 1;
        let hari = 1;

        // Iterasi hingga satu bulan penuh
        while (hari <= 30) {
            karyawan.forEach(pegawai => {
                // Jika masih tersedia shift dan sisa waktu mencukupi, tambahkan karyawan ke shift
                while (shift <= 4 && sisaWaktu >= pegawai.jam_kerja) {
                    if (!jadwal[hari]) jadwal[hari] = {};
                    if (!jadwal[hari][shift]) jadwal[hari][shift] = [];
                    jadwal[hari][shift].push(pegawai);
                    sisaWaktu -= pegawai.jam_kerja;
                    shift++;
                }
            });

            // Jika sudah mencapai shift maksimal, reset shift dan sisa waktu, lanjutkan ke hari berikutnya
            if (shift > 4 || sisaWaktu < 0) {
                shift = 1;
                sisaWaktu = waktu_kerja_maksimum;
                hari++;
            }
        }

        this.setState({ jadwal });
    }

    render() {
        const { jadwal } = this.state;
        return (
            <div>
                <h2>Hasil Jadwal Kerja:</h2>
                {jadwal.map((shifts, hari) => (
                    <div key={hari}>
                        <h3>Hari {hari + 1}:</h3>
                        {Object.keys(shifts).map(shift => (
                            <div key={shift}>
                                <h4>Shift {shift}:</h4>
                                {shifts[shift].map(pegawai => (
                                    <p key={pegawai.nama}>{pegawai.nama} - Jam Kerja: {pegawai.jam_kerja} jam</p>
                                ))}
                            </div>
                        ))}
                    </div>
                ))}
            </div>
        );
    }
}

export default JadwalKerja;
