import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import {
  Button,
  Form,
  FloatingLabel,
  Container,
  Nav,
  Navbar,
  NavDropdown,
  Card,
  Tab,
  Tabs,
} from "react-bootstrap";
// import swal from 'sweetalert';
import { ToastContainer, toast } from "react-toastify";
import Icon from "../components/icons";
import PasswordField from "../components/passwordField";
import { PasswordChecker } from "react-password-strengthbar-ui";
import LoadingScreen from "../components/loadingScreen";

axios.defaults.baseURL = "https://demo-apiabsensi.kazuyamedia.com/api";
axios.defaults.headers.post["Content-Type"] = "application/json";

function App() {
  const navigate = useNavigate();
  const [dataModel, setDataModel] = useState([]);
  const [mode, setMode] = useState("form");
  const [update, setUpdate] = useState(false);
  const [search, setSearch] = useState("");
  const [isProcessing, setIsProcessing] = useState(false);

  const [selectedRows, setSelectedRows] = React.useState([]);
  const [title, setTitle] = useState("List data Pengguna");
  const [toggleCleared, setToggleCleared] = React.useState(false);
  const [dataPenggunaLogin, setdataPenggunaLogin] = useState({
    IDCABANG: "",
    IDUSER_ABSENSI: "",
    IDUSER: "",
    EMAIL: "",
    NAMALENGKAP: "",
    NAMA_PERUSAHAAN: "",
    HP: "",
    ALMT: "",
    EMAIL_LOGIN: "",
    PASS: "",
  });

  const getDataPengguna = () => {
    let IDCABANG = sessionStorage.getItem("IDCABANG", true);
    let IDUSER_ABSENSI = sessionStorage.getItem("IDUSER_ABSENSI", true);
    let IDUSER = sessionStorage.getItem("IDUSER", true);
    let EMAIL = sessionStorage.getItem("EMAIL", true);
    let NAMALENGKAP = sessionStorage.getItem("NAMALENGKAP", true);
    let NAMA_PERUSAHAAN = sessionStorage.getItem("NAMA_PERUSAHAAN", true);
    let HP = sessionStorage.getItem("HP", true);
    let ALAMAT = sessionStorage.getItem("ALAMAT", true);
    let EMAIL_LOGIN = sessionStorage.getItem("EMAIL_LOGIN", true);
    setdataPenggunaLogin({
      ...dataPenggunaLogin,
      IDCABANG: IDCABANG,
      IDUSER_ABSENSI: IDUSER_ABSENSI,
      IDUSER: IDUSER,
      EMAIL: EMAIL,
      NAMALENGKAP: NAMALENGKAP,
      NAMA_PERUSAHAAN: NAMA_PERUSAHAAN,
      HP: HP,
      ALMT: ALAMAT,
      EMAIL_LOGIN: EMAIL_LOGIN,
    });
  };

  const UpdateUserLogin = () => {
    setIsProcessing(true);
    //  console.log(dataPenggunaLogin);
    var config = {
      method: "post",
      url: "Person/UpdateDataUser",
      headers: { "Content-Type": "multipart/form-data" },
      data: dataPenggunaLogin,
    };
    axios(config)
      .then(function (res) {
        console.log(res);
        let Status = res.data.status;
          if (Status == 200) { 
            destroyAccess();
            setIsProcessing(false);
          }else { // navigate("/ProfilSaya", { replace: true })
              console.log("GagalUpdateData");
        }
  })
      .catch(function (error) {
        toast("Status : " + error);
      });
  };

  function setDataForm(e) {
    setdataPenggunaLogin({
      ...dataPenggunaLogin,
      [e.target.name]: e.target.value,
    });
    console.log(dataPenggunaLogin);
  }

  const Navigate = (key) => {
    navigate("/" + key, { replace: true });
  };

  const acceptPolicy = () => {
    try {
      let status = sessionStorage.getItem("Status", true);
      let message = sessionStorage.getItem("Message", true);
      if (status != 200) {
        navigate("/", { replace: true });
      }
      toast("Message : " + message);
    } catch (err) {
      let Message = sessionStorage.getItem("Message", true);
      sessionStorage.setItem("Message", Message);
      navigate("/", { replace: true });
    }
  };

  // function destroy Access
  function destroyAccess() {
    setIsProcessing(true);
    setTimeout(() => {
      sessionStorage.clear();
      sessionStorage.setItem("Message", "You must log in first!");
      navigate("/", { replace: true });
    }, 1500);
  }

  useEffect(() => {
    acceptPolicy();
    getDataPengguna();
  }, []);

  return (
    <div className="container-fluid">
      {isProcessing ? <LoadingScreen /> : null}
      <div className="row">
        <div className="col-12 px-0 z-index-1 position-absolute">
          <Navbar className="bg-default rounded-bottom py-2">
            <Container fluid>
              <Navbar.Brand href="#home" className="text-white">
                <a onClick={(key) => Navigate("ProfilSaya")}>
                  <Icon.BackIcon />
                </a>
                <span className="ms-3">Edit Profil</span>
              </Navbar.Brand>
            </Container>
          </Navbar>
        </div>

        <div className="col-md-12 offset-md-12 text-center mt-5 pt-5">
          <Card.Img
            variant="top rounded-circle img-photo"
            src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTWYagfjtHRSwXJKfn_LOgXzAsS1rYL96U_-AGCSpbQDledTIT-6oaxYiEE3Tdv7p1zT7E&usqp=CAU"
          />
          <Card.Subtitle className="mb-2 text-muted pt-3">
            <Card.Link className="text-decoration-none" href="#">
              Ubah Foto
            </Card.Link>
          </Card.Subtitle>
        </div>

        <div data-cy="form-login" className="col-12 px-0 mt-3">
          <div className="bg-white bg-gradient rounded-4 shadow p-3 py-3 mx-4 mb-4">
            <FloatingLabel className="mt-2" label="Id">
              <Form.Control
                type="text"
                value={dataPenggunaLogin.IDUSER_ABSENSI}
                id={"name"}
                disabled
              />
            </FloatingLabel>

            <FloatingLabel className="mt-4" label="Nama Lengkap">
              <Form.Control
                type="text"
                value={dataPenggunaLogin.NAMALENGKAP}
                onChange={setDataForm}
                id={"name"}
                name={"NAMALENGKAP"}
                disabled
              />
            </FloatingLabel>

            <FloatingLabel className="mt-4" label="Cabang">
              <Form.Control
                type="text"
                value={dataPenggunaLogin.NAMA_PERUSAHAAN}
                id={"name"}
                disabled
              />
            </FloatingLabel>

            <FloatingLabel className="mt-4" label="Email address">
              <Form.Control
                type="EMAIL"
                placeholder="name@example.com"
                value={dataPenggunaLogin.EMAIL_LOGIN}
                onChange={setDataForm}
                id={"name"}
                name={"EMAIL_LOGIN"}
              />
            </FloatingLabel>

            <PasswordField
              dataRecord={{
                data: dataPenggunaLogin.PASS,
                onChange: setDataForm,
                name: "PASS",
              }}
            />
            <Form.Text id="passwordHelpBlock font-2" muted>
              Perkuat dengan kapital, Symbol & Angka.
            </Form.Text>
            <PasswordChecker password={dataPenggunaLogin.PASS} />

            <FloatingLabel className="mt-4" label="Alamat">
              <Form.Control
                type="text"
                value={dataPenggunaLogin.ALMT}
                onChange={setDataForm}
                id={"name"}
                name={"ALMT"}
              />
            </FloatingLabel>

            <div className="text-center mt-4 d-grid gap-2">
              <Button
                onClick={UpdateUserLogin}
                variant="default text-white rounded-3 py-3"
                size="sm"
              >
                Simpan
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default App;
