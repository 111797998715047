import { useState } from "react";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";

function AlertComp(props) {
  let R = props.Recordmaterial;
  const [show, setShow] = useState(true);

  if (show) {
    return (
      <Alert variant={R.bgVariant} className="mt-4 p-3" onClose={() => setShow(false)} >
        <Alert.Heading>Info!</Alert.Heading>
        <p>{R.message}</p>
      </Alert>
    );
  }
}

export default AlertComp;
