import React, { Component, StrictMode } from "react";
import { BrowserRouter, Router, Route, Routes, Link } from "react-router-dom";
import ReactDOM from "react-dom/client";
import "bootstrap";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/css/custom.css";

// component App
import App from "./App";
import LoginAkun from "./activity/LoginAkun";
import BuatAkun from "./activity/BuatAkun";
import HankoAuth from "./activity/HankoAuth"; // login with metode passkey

import Dashboard from "./activity/Dashboard";
import RiwayatAbsensi from "./activity/RiwayatAbsensi";
import ScanQr from "./activity/ScanQr";
import Tesloadingscreen from "./activity/LoadingScreen";
import CheckHelper from "./components/checkHelper";

import ProfilSaya from "./activity/ProfilSaya";
import EditProfilSaya from "./activity/EditProfilSaya";
import Testscanqr from "./activity/TesQr";
// import Tes from "./Tes";
// import TestImei from "./TestEmai";
import Jadwalkerja from "./activity/test-jadwal-kerja"; // helper generate jadwal.
import Testscanqr02 from "./activity/React-qr-scanner";
import Jadwal from "./activity/Jadwal";

import reportWebVitals from "./reportWebVitals";

 

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<LoginAkun />} />
        <Route path="/BuatAkun" element={<BuatAkun />} />
        <Route path="/Dashboard" element={<Dashboard />} />
        <Route path="/CheckHelper" element={<CheckHelper />} />
        <Route path="/RiwayatAbsensi" element={<RiwayatAbsensi />} />
        <Route path="/ProfilSaya" element={<ProfilSaya />} />
        <Route path="/EditProfilSaya" element={<EditProfilSaya />} />
        <Route path="/ScanQr" element={<ScanQr />} />
        <Route path="/tesloading" element={<Tesloadingscreen />} />
        <Route path="/jadwalkerja" element={<Jadwalkerja />} />
        <Route path="/jadwalku" element={<Jadwal />} />
        {/* <Route path="/test" element={<Tes />} /> */}
        {/* <Route path="/test-imei" element={<TestImei />} /> */}
        {/* <Route path="/test-scanqr-02" element={<Testscanqr02 />} /> */}
        {/* <Route path="/camera-test" element={<TesPermission />} /> */}
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);
//
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
