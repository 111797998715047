import React, { useEffect, useState } from "react";
import {
  Button,
  Form,
  FloatingLabel,
  Container,
  Nav,
  Navbar,
  NavDropdown,
  Card,
  Tab,
  Tabs,
} from "react-bootstrap";
// import swal from 'sweetalert';
import { ToastContainer, toast } from "react-toastify";
import Icon from "../components/icons";
import { useNavigate } from "react-router-dom";
import LoadingScreen from "../components/loadingScreen";

function App() {
  const navigate = useNavigate();
  const [dataModel, setDataModel] = useState([]);
  const [mode, setMode] = useState("form");
  const [update, setUpdate] = useState(false);
  const [search, setSearch] = useState("");
  const [isProcessing, setIsProcessing] = useState(false)
  
  const [selectedRows, setSelectedRows] = React.useState([]);
  const [title, setTitle] = useState("List data Pengguna");
  const [toggleCleared, setToggleCleared] = React.useState(false);
  const [dataPenggunaLogin, setdataPenggunaLogin] = useState({
    IDUSER_ABSENSI: "",
    IDUSER: "",
    EMAIL: "",
    EMAIL_LOGIN: "",
    IDCABANG: "",
    NAMALENGKAP: "",
    NAMA_PERUSAHAAN: "",
    HP: "",
    PASS: "",
    ALAMAT: "",
  });

  const getDataPengguna = () => {
    let IDCABANG = sessionStorage.getItem("IDCABANG", true);
    let IDUSER_ABSENSI = sessionStorage.getItem("IDUSER_ABSENSI", true);
    let IDUSER = sessionStorage.getItem("IDUSER", true);
    let EMAIL = sessionStorage.getItem("EMAIL", true);
    let NAMALENGKAP = sessionStorage.getItem("NAMALENGKAP", true);
    let NAMA_PERUSAHAAN = sessionStorage.getItem("NAMA_PERUSAHAAN", true);
    let HP = sessionStorage.getItem("HP", true);
    let ALAMAT = sessionStorage.getItem("ALAMAT", true);
    let EMAIL_LOGIN = sessionStorage.getItem("EMAIL_LOGIN", true);
    setdataPenggunaLogin({
      ...dataPenggunaLogin,
      IDCABANG: IDCABANG,
      IDUSER_ABSENSI: IDUSER_ABSENSI,
      IDUSER: IDUSER,
      EMAIL: EMAIL,
      NAMALENGKAP: NAMALENGKAP,
      NAMA_PERUSAHAAN: NAMA_PERUSAHAAN,
      HP: HP,
      ALAMAT: ALAMAT,
      EMAIL_LOGIN: EMAIL_LOGIN,
    });
  };

  const Navigate = (key) => {
    navigate("/" + key, { replace: true });
  };

  const acceptPolicy = () => {
    try {
      let status = sessionStorage.getItem("Status", true);
      let message = sessionStorage.getItem("Message", true);
      if (status != 200) {
        navigate("/", { replace: true });
      }
      // toast("Message : " + message);
    } catch (err) {
      let Message = sessionStorage.getItem("Message", true);
      sessionStorage.setItem("Message", Message);
      navigate("/", { replace: true });
    }
  };

  const pageToEditProfile = () => {
    setIsProcessing(true);
    setTimeout(() => {
      setIsProcessing(false);
      navigate("/EditProfilSaya", { replace: true });
    }, 800)
  };


  // function destroy Access
  function destroyAccess() {
    setIsProcessing(true);
    setTimeout(() => {
      sessionStorage.clear();
      sessionStorage.setItem("Message", "You must log in first!");
      navigate("/", { replace: true });
    }, 1500)
  }
  useEffect(() => {
    acceptPolicy();
    getDataPengguna();
  }, []);

  return (
    <div className="container-fluid">
      {isProcessing ? <LoadingScreen /> : ""}
      <div className="row">
        <div className="col-12 px-0 z-index-1 position-absolute">
          <Navbar className="bg-success bg-gradient rounded-bottom py-2">
            <Container fluid>
              <Navbar.Brand className="text-white">
                <a onClick={(key) => Navigate("Dashboard")}>
                  <Icon.BackIcon />
                </a>
                <span className="ms-3">Profil Saya</span>
              </Navbar.Brand>
            </Container>
          </Navbar>
        </div>
        
        <div className="col-md-12 offset-md-12 text-center mt-5 pt-5">
          <Card.Img
            variant="top rounded-circle img-photo"
            src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTWYagfjtHRSwXJKfn_LOgXzAsS1rYL96U_-AGCSpbQDledTIT-6oaxYiEE3Tdv7p1zT7E&usqp=CAU"
          />
          <h5 className="card-title pt-3 text-uppercase">{dataPenggunaLogin.NAMALENGKAP}</h5>
          <Card.Subtitle className="mb-3 text-muted pt-1">
            {dataPenggunaLogin.EMAIL}
          </Card.Subtitle>
        </div>

        <div className="col-12 px-0 mt-2">
          <div className="bg-white bg-gradient rounded-2 shadow-sm py-0 mx-2 mb-3">
            <ul className="p-3">
              <li className="list-group-item d-flex justify-content-between align-items-center">
                Detail Informasi Profil
                <span className="bg-white rounded-pill">
                  <a
                    onClick={pageToEditProfile}
                    className="text-decoration-none pe-0"
                  >
                    Edit <Icon.ArrowRight />
                  </a>
                </span>
              </li>
            </ul>
          </div>
        </div>

        <div data-cy="form-login" className="col-12 px-0">
          <div className="bg-white bg-gradient rounded-2 shadow-sm py-0 mx-2 mb-4">
            <ul className="list-group">
              <li className="list-group-item d-flex justify-content-between align-items-center">
                Id
                <span className="text-secondary rounded-pill">
                  {dataPenggunaLogin.IDUSER_ABSENSI}
                </span>
              </li>
              <li className="list-group-item d-flex justify-content-between align-items-center">
                Nama Lengkap
                <span className="text-secondary rounded-pill">
                  {dataPenggunaLogin.NAMALENGKAP}
                </span>
              </li>
              <li className="list-group-item d-flex justify-content-between align-items-center">
                Nama Perusahaan
                <span className="text-secondary rounded-pill">
                  {dataPenggunaLogin.NAMA_PERUSAHAAN}
                </span>
              </li>
              <li className="list-group-item d-flex justify-content-between align-items-center">
                No Handphone
                <span className="text-secondary rounded-pill">
                  {dataPenggunaLogin.HP}
                </span>
              </li>
              <li className="list-group-item d-flex justify-content-between align-items-center">
                Email Login
                <span className="text-secondary rounded-pill">
                  {dataPenggunaLogin.EMAIL_LOGIN}
                </span>
              </li>
              <li className="list-group-item d-flex justify-content-between align-items-center">
                Password
                <span className="text-secondary rounded-pill">***********</span>
              </li>
              <li className="list-group-item d-flex justify-content-between align-items-center">
                Alamat
                <span className="text-secondary rounded-pill">
                  {dataPenggunaLogin.ALAMAT}
                </span>
              </li>

              <li className="list-group-item d-flex justify-content-between align-items-center">
                <a onClick={destroyAccess}>Logout</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}
export default App;
