import React from "react";
import Icon from "../components/icons";

// check absensi icons / indicator.
export default function CheckIconAbsensi(props) {
    let R = props.dataRecord;

    switch (R.status) {
      case "CheckIn":
        return <Icon.checkIcon />;
        break;

      case "Pending":
        return <Icon.pendingIcon />;
        break;

      case "Completed":
        return <Icon.complatedIcon />;
        break;

      default:
        return null;
        break;
    }
  };