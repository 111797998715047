// src/QRScanner.js
import React, { useState } from "react";
import QRReader from "react-qr-reader";

function QRScanner() {
  const [result, setResult] = useState("No QR code scanned yet.");

  const handleScan = (data) => {
    if (data) {
    fetch("http://localhost:3000/items", {
      method: "POST",
      body: JSON.stringify({ data }),
      headers: { "Content-Type": "application/json" }
    })
    .then((res) => res.json())
    .then((response) => {
      console.log("Success:", JSON.stringify(response));
    })
    .catch((error) => {
      console.error("Error:", error);
    });
   }
 };

  return (
    <div>
      <QRReader onScan={handleScan} />
      <p>{result}</p>
    </div>
  );
}

export default QRScanner;