import { useState } from "react";
import Form from "react-bootstrap/Form";
import {InputGroup} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

function BasicExample(props) {
    let R = props.dataRecord;
    const [passwordShown, setPasswordShown] = useState(false);
    
  const eye = passwordShown ? <FontAwesomeIcon icon={faEye} /> : <FontAwesomeIcon icon={faEyeSlash}/>
  
  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };


  return (
    <>
    <div className="input-group mt-4" >
        <div className="flex-grow-1 form-floating">
          <input
           type={passwordShown ? "text" : "password"}
           name={R.name}
           value={R.data}
           onChange={R.onChange}
            className="rounded-0 rounded-start form-control"
          />
          <label>Password</label>
        </div>
        <InputGroup.Text>
        <i onClick={togglePasswordVisiblity}>{eye}</i>{" "}
        </InputGroup.Text>
    </div>
    
    </>
  );
}

export default BasicExample;